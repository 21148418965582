export default {
  methods: {
    getDateRange(type, filterData) {
      const filter = filterData;
      const ranges = {
        today: function () {
          return new Date().toISOString().split("T")[0];
        },

        this_week: function () {
          const now = new Date(); // current date
          const firstDay = now.getDate() - now.getDay(); // date of th 1st day of current week
          const firstDayDate = new Date(now.setDate(firstDay))
            .toISOString()
            .split("T")[0];
          const today = new Date().toISOString().split("T")[0];
          return [firstDayDate, today];
        },

        last_week: function () {
          const lastSunday = new Date().setDate(
            new Date().getDate() - new Date().getDay() - 7
          );
          const lastFriday = new Date().setDate(
            new Date().getDate() - new Date().getDay() - 1
          );

          const lastSundayDate = new Date(lastSunday)
            .toISOString()
            .split("T")[0];
          const lastFridayDate = new Date(lastFriday)
            .toISOString()
            .split("T")[0];

          return [lastSundayDate, lastFridayDate];
        },

        last_7_days: function () {
          const firstDay = new Date().setDate(new Date().getDate() - 7);
          const firstDayDate = new Date(firstDay).toISOString().split("T")[0];

          const lastDay = new Date().setDate(new Date().getDate() - 1);
          const lastDayDate = new Date(lastDay).toISOString().split("T")[0];

          return [firstDayDate, lastDayDate];
        },

        this_month: function () {
          const date = new Date();
          const firstDay = new Date(
            new Date(date.getFullYear(), date.getMonth(), 1) -
              date.getTimezoneOffset() * 60000
          )
            .toISOString()
            .split("T")[0];

          const lastDay = new Date().toISOString().split("T")[0];
          return [firstDay, lastDay];
        },

        last_month: function () {
          const date = new Date();
          const firstDay = new Date(
            new Date(date.getFullYear(), date.getMonth() - 1, 1) -
              date.getTimezoneOffset() * 60000
          )
            .toISOString()
            .split("T")[0];
          const lastDay = new Date(
            new Date(date.getFullYear(), date.getMonth(), 0) -
              date.getTimezoneOffset() * 60000
          )
            .toISOString()
            .split("T")[0];
          return [firstDay, lastDay];
        },

        last_30_days: function () {
          const today = new Date();
          const firstDay = new Date(new Date().setDate(today.getDate() - 30))
            .toISOString()
            .split("T")[0];
          return [firstDay, today.toISOString().split("T")[0]];
        },

        this_year: function () {
          const date = new Date();
          const firstDay = new Date(
            new Date(date.getFullYear(), date.getMonth(), 1) -
              date.getTimezoneOffset() * 60000
          )
            .toISOString()
            .split("T")[0];

          return [firstDay, date.toISOString().split("T")[0]];
        },

        last_year: function () {
          const date = new Date();
          const firstDay = new Date(
            new Date(date.getFullYear() - 1, date.getMonth(), 1) -
              date.getTimezoneOffset() * 60000
          )
            .toISOString()
            .split("T")[0];

          const lastDay = new Date(
            new Date(date.getFullYear() - 1, date.getMonth() + 11, 31) -
              date.getTimezoneOffset() * 60000
          )
            .toISOString()
            .split("T")[0];

          return [firstDay, lastDay];
        },

        all_time: function () {
          const firstDay = new Date(0).toISOString().split("T")[0];
          const lastDay = new Date().toISOString().split("T")[0];

          return [firstDay, lastDay];
        },

        custom: function () {
          const firstDay = filter.date_range[1];
          const lastDay = filter.date_range[1];

          return [firstDay, lastDay];
        },
      };
      // return ranges[type]();
      return [
        { name: "date_range.date", value: ranges[type]() },
        {
          name: "date_range.select",
          value: "orderCreationTime",
        },
      ];
    },

    getOrderStatus(name) {
      const filterStatuses = this.$store.getters.getDELIVERYFilterStatuses;

      const status = {
        delivery_orders_counter_chart: function () {
          return filterStatuses.confirmed;
        },
        delivery_under_process_counter_chart: function () {
          return filterStatuses.under_process;
        },
        delivery_shipped_counter_chart: function () {
          return filterStatuses.shipped;
        },
        delivery_status_pending_counter_chart: function () {
          return filterStatuses.pending;
        },
        delivery_status_out_for_delivery_counter_chart: function () {
          return filterStatuses.out_for_delivery;
        },
        delivery_status_delivered_counter_chart: function () {
          return filterStatuses.delivered;
        },
        delivery_status_returned_counter_chart: function () {
          return filterStatuses.returned;
        },
      };
      return {
        name: "orderStatus",
        value: status[name](),
      };
    },
  },
};
