<template>
  <div
    v-if="filter.type === 'autocompSingle'"
    id="order-dashboard-autocomplete"
  >
    <v-autocomplete
      class="px-2 py-1 filter-input"
      dense
      v-model="filterData[`${filter.name}`]"
      :items="filter.values"
      item-text="text"
      item-value="index"
      :label="filter.title"
      @change="handleChange"
      single-line
      hide-details
      solo
      :menu-props="menuProps"
      menu-class="custom-autocomplete-menu"
    >
      <template v-slot:prepend-inner>
        <inline-svg class="px-1" width="26" :src="filter.icon"></inline-svg>
      </template>
    </v-autocomplete>
  </div>
  <div id="order-dashboard-select" v-else-if="filter.type === 'select2Single'">
    <v-select
      class="px-2 py-1 filter-input"
      dense
      v-model="filterData[`${filter.name}`]"
      :items="filter.values"
      item-text="text"
      item-value="index"
      :label="filter.title"
      @change="handleChange"
      solo
      single-line
      hide-details
    >
      <template v-slot:prepend-inner>
        <inline-svg class="px-1" width="26" :src="filter.icon"></inline-svg>
      </template>
    </v-select>
  </div>
</template>

<script>
export default {
  name: "FilterTypeNew",
  props: ["filter", "updateFunction"],
  data: () => ({
    filterData: {},
    menuProps: { maxWidth: "250px", padding: "10px", backgroundColor: "white" },
  }),
  methods: {
    handleChange() {
      this.updateFunction(this.filterData);
    },
  },
};
</script>

<style lang="scss">
.filter-input {
  .v-input__control {
    .v-input__slot {
      box-shadow: none !important;
      font-size: 10px;
      font-weight: 600;
      border-radius: 7px;
      padding: 8px;
    }
  }
}

#order-dashboard-autocomplete,
#order-dashboard-select {
  .v-label {
    font-size: 13px !important;
  }
}
</style>
