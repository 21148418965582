<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="1000"
  >
    <!--begin::Modal dialog-->
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header py-4 align-center">
        <h4 class="mb-0 font-weight-bolder">Other Countries</h4>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--begin::Modal header-->
      <!--begin::Modal body-->
      <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7">
        <!--begin::Body-->

        <!--end::Body-->
        <apexchart
          type="bar"
          height="300"
          :options="chartOptions"
          :series="series"
        ></apexchart>
        <!--begin::Actions-->
        <div class="my-4 d-flex flex-grow-1 align-end justify-center">
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="toggleModal"
          >
            Close
          </button>
        </div>
        <!--end::Actions-->
      </div>
      <!--end::Modal body-->
    </div>
    <!--end::Modal dialog-->
  </v-dialog>
</template>

<script>
export default {
  name: "FulfillmentPerCountryColumnChartOtherCountriesModal",
  props: ["serverData"],
  components: {},
  data: () => ({
    dialog: false,
  }),
  methods: {
    toggleModal: function () {
      this.dialog = !this.dialog;
    },
  },
  computed: {
    chartOptions: function () {
      return {
        chart: {
          type: "bar",
          toolbar: {
            show: false,
          },
          events: {
            // dataPointSelection: function (event, chartContext, config) {
            //   console.log(event);
            //   console.log(chartContext);
            //   console.log(config);
            // },
            // seriesIndex: function (event, chartContext, config) {
            //   console.log(event);
            //   console.log(chartContext);
            //   console.log(config);
            // },
          },
        },

        fill: {
          colors: ["#0093f5"],
          opacity: 1,
        },

        plotOptions: {
          bar: {
            borderRadius: 7,
            columnWidth: "25%",
            dataLabels: {
              position: "top", // top, center, bottom// offset by 20px
            },
          },
        },

        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val;
          },
          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },

        xaxis: {
          labels: {
            show: true,
            style: {
              colors: "#b1b5c3",
              fontSize: "12px",
            },
          },
          axisBorder: {
            show: false,
          },
          position: "bottom",
          tooltip: {
            enabled: false,
          },
          categories: this.serverData?.values.categories_others,
        },

        yaxis: {
          axisBorder: {
            show: false,
          },
          labels: {
            show: true,
            formatter: function (val) {
              return val + "";
            },
            style: {
              colors: ["#afb2c1"],
              fontSize: "12px",
              fontWeight: 500,
            },
          },
        },

        title: {
          floating: true,
          offsetY: 330,
          align: "center",
          style: {
            color: "#444",
          },
        },

        grid: {
          show: "true",
          borderColor: "#b0b4c2",
          strokeDashArray: 4,
          position: "back",
        },

        tooltip: {
          enabled: false,
        },
      };
    },
    series: function () {
      let series = this.serverData?.values.series_others.map((serie) => {
        return serie.data[0];
      });
      return [{ data: series, name: "" }];
    },
  },
  mounted() {},
};
</script>
