<template>
  <div class="pt-4 pb-3 mt-5">
    <div class="h3 text-capitalize mb-0">
      {{ item.title }}
    </div>
  </div>
</template>

<script>
export default {
  name: "DashboardLabelNew",
  props: ["item"],
};
</script>
