<template>
  <div class="custom-content-height pb-8 poppins">
    <div class="container-fluid">
      <div class="row justify-content-between">
        <div class="col-12 col-md-auto">
          <h3 class="font-weight-bolder mb-1">Orders Dashboard</h3>
          <p class="text-dark-50 font-weight-bold">Home - Dashboards</p>
        </div>
        <div
          class="col-12 col-md-auto pt-0 pt-md-4 d-lg-flex flex-wrap mr-5 mr-xl-8"
        >
          <FilterSelector
            :filters="filters"
            :update-data-of-charts="updateDataOfCharts"
          ></FilterSelector>
          <div class="ml-2 ml-md-0 pr-2 pr-md-0 w-lg-200px">
            <!--  -->
            <div style="margin-top: 10px">
              <!--begin::Datepicker-->
              <DateTimePicker @on-submit="getDateRange" position="left" />
              <!--end::Datepicker-->
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container-fluid">
      <div class="d-flex row px-3 px-xl-6" v-if="showData">
        <div
          class="ma-0 py-0 px-1"
          :class="{
            'col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 pb-2':
              item.type == 'counter' ? true : false,
            'pr-sm-5 pr-md-0':
              (item.type === 'counter' && index === 1) ||
              (index === 3) | (index === 6) | (index === 8),
            'col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 py-xl-4 mb-4 mb-lg-0':
              item.type == 'column' || item.type == 'donut' ? true : false,
            'col-12': item.type == 'area' || 'label' ? true : false,
            'pr-lg-6 pr-xl-7 pb-1 pb-lg-0': item.type === 'donut',
            'pl-lg-1 ': item.type === 'column',
            'pt-lg-3 pt-xl-0': item.type === 'area',
          }"
          v-for="(item, index) in dashboards"
          :key="`chart_${index}`"
        >
          <fulfillment-per-country-column-chart
            v-if="item.type == 'column'"
            :item="item"
            :filterData="dataOfFilters()"
          ></fulfillment-per-country-column-chart>
          <FulfillmentPerformanceDonutChart
            class="mt-4"
            v-if="item.type == 'donut'"
            :item="item"
            :filterData="dataOfFilters()"
          ></FulfillmentPerformanceDonutChart>
          <fulfillment-orders-counter-chart
            v-if="item.type == 'counter'"
            :item="item"
            :index="index"
            :filterData="dataOfFilters()"
          ></fulfillment-orders-counter-chart>
          <OrdersProgressAreaChart
            class="mt-5"
            v-if="item.type == 'area'"
            :item="item"
            :filterData="dataOfFilters()"
          ></OrdersProgressAreaChart>
          <dashboard-label v-if="item.type == 'label'" :item="item">
          </dashboard-label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DateTimePicker from "@/own/components/DateTimePicker.vue";
import FulfillmentOrdersCounterChart from "@/own/components/dashboard/FulfillmentOrdersCounterChartNew.vue";
import FulfillmentPerformanceDonutChart from "@/own/components/dashboard/FulfillmentPerformanceDonutChartNew.vue";
import OrdersProgressAreaChart from "@/own/components/dashboard/OrdersProgressAreaChartNew.vue";
import FulfillmentPerCountryColumnChart from "@/own/components/dashboard/FulfillmentPerCountryColumnChartNew.vue";
import DashboardLabel from "@/own/components/dashboard/DashboardLabelNew.vue";
import FilterSelector from "@/own/components/dashboard/filters/FilterSelectorNew";

export default {
  name: "DashboardLayoutNew",
  props: ["dashboards", "updateDashboardData", "filters"],
  components: {
    DashboardLabel,
    FulfillmentOrdersCounterChart,
    FulfillmentPerformanceDonutChart,
    OrdersProgressAreaChart,
    FulfillmentPerCountryColumnChart,
    FilterSelector,
    DateTimePicker,
  },
  data() {
    return {
      dialog: false,
      isVisible: true,
      filterData: {},
      dateRange: [this.today(), this.today()],
    };
  },
  methods: {
    dateFormatter(date, charForJoin = "-") {
      return [
        date.getFullYear(),
        date.getMonth() + 1 > 9
          ? date.getMonth() + 1
          : `0${date.getMonth() + 1}`,
        date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`,
      ].join(charForJoin);
    },
    dataOfFilters() {
      const filterData = {
        date_range_type: "custom",
        date_range: this.dateRange,
        ...this.filterData,
      };
      return filterData;
    },

    updateDataOfCharts(data) {
      this.filterData = { ...this.filterData, ...data };
      this.updateDashboardData(this.dataOfFilters(), this.filterData);
    },
    getDateRange(val) {
      this.dateRange = val;
      this.updateDataOfCharts(this.filterData);
    },
    today() {
      const d = new Date();
      return this.dateFormatter(d);
    },
  },
  computed: {
    showData: function () {
      return this.isVisible;
    },
    isClient: function () {
      return !this.$store.getters.currentUser.data.is_client;
    },
    datePickerPosition: function () {
      if (this.$vuetify.breakpoint.mdAndUp) {
        return "left";
      }
      return "";
    },
  },
};
</script>
<style>
.custom-input {
  outline: none !important;
  border: none !important;
}
.daterangepicker .drp-calendar td.active {
  background-color: rgba(126, 4, 183, 0.8) !important;
}
.in-range {
  background-color: #f7e6fe !important;
}
</style>
