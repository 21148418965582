<template>
  <div class="d-flex justify-end align-center flex-wrap flex-md-nowrap">
    <div
      class="w-100 w-lg-200px ma-0 pa-0"
      v-for="filter in filters"
      :key="filter.name"
    >
      <FilterType
        :filter="filter"
        :updateFunction="updateDataOfCharts"
      ></FilterType>
    </div>
  </div>
</template>

<script>
import FilterType from "@/own/components/dashboard/filters/FilterTypeNew";
export default {
  name: "FilterSelector",
  props: ["filters", "updateDataOfCharts"],
  components: { FilterType },
};
//TODO::Dasboard:: delete old filter file
</script>
