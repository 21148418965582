var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"custom-content-height pb-8 poppins"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-between"},[_vm._m(0),_c('div',{staticClass:"col-12 col-md-auto pt-0 pt-md-4 d-lg-flex flex-wrap mr-5 mr-xl-8"},[_c('FilterSelector',{attrs:{"filters":_vm.filters,"update-data-of-charts":_vm.updateDataOfCharts}}),_c('div',{staticClass:"ml-2 ml-md-0 pr-2 pr-md-0 w-lg-200px"},[_c('div',{staticStyle:{"margin-top":"10px"}},[_c('DateTimePicker',{attrs:{"position":"left"},on:{"on-submit":_vm.getDateRange}})],1)])],1)])]),_c('div',{staticClass:"container-fluid"},[(_vm.showData)?_c('div',{staticClass:"d-flex row px-3 px-xl-6"},_vm._l((_vm.dashboards),function(item,index){return _c('div',{key:`chart_${index}`,staticClass:"ma-0 py-0 px-1",class:{
          'col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 pb-2':
            item.type == 'counter' ? true : false,
          'pr-sm-5 pr-md-0':
            (item.type === 'counter' && index === 1) ||
            (index === 3) | (index === 6) | (index === 8),
          'col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 py-xl-4 mb-4 mb-lg-0':
            item.type == 'column' || item.type == 'donut' ? true : false,
          'col-12': item.type == 'area' || 'label' ? true : false,
          'pr-lg-6 pr-xl-7 pb-1 pb-lg-0': item.type === 'donut',
          'pl-lg-1 ': item.type === 'column',
          'pt-lg-3 pt-xl-0': item.type === 'area',
        }},[(item.type == 'column')?_c('fulfillment-per-country-column-chart',{attrs:{"item":item,"filterData":_vm.dataOfFilters()}}):_vm._e(),(item.type == 'donut')?_c('FulfillmentPerformanceDonutChart',{staticClass:"mt-4",attrs:{"item":item,"filterData":_vm.dataOfFilters()}}):_vm._e(),(item.type == 'counter')?_c('fulfillment-orders-counter-chart',{attrs:{"item":item,"index":index,"filterData":_vm.dataOfFilters()}}):_vm._e(),(item.type == 'area')?_c('OrdersProgressAreaChart',{staticClass:"mt-5",attrs:{"item":item,"filterData":_vm.dataOfFilters()}}):_vm._e(),(item.type == 'label')?_c('dashboard-label',{attrs:{"item":item}}):_vm._e()],1)}),0):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 col-md-auto"},[_c('h3',{staticClass:"font-weight-bolder mb-1"},[_vm._v("Orders Dashboard")]),_c('p',{staticClass:"text-dark-50 font-weight-bold"},[_vm._v("Home - Dashboards")])])
}]

export { render, staticRenderFns }