<template v-if="isDataLoaded">
  <div class="p-4">
    <dashboard-layout
      :dashboards="dashboards"
      :updateDashboardData="updateDashboardData"
      :filters="filters"
    ></dashboard-layout>
  </div>
</template>

<script>
import { UPDATE_DASHBOARD_DATA } from "@/core/services/store/delivery.module";
import DashboardLayout from "@/own/components/dashboard/DashboardLayoutNew.vue";
export default {
  components: { DashboardLayout },
  name: "Delivery",
  methods: {
    updateDashboardData: function (
      datas = { date_range_type: "today", date_range: null }
    ) {
      // if (datas.date == "custom") {
      //   await this.$store.dispatch(UPDATE_DASHBOARD_DATA, datas);
      // } else {
      //   await this.$store.dispatch(UPDATE_DASHBOARD_DATA, datas);
      // }
      this.$store.dispatch(UPDATE_DASHBOARD_DATA, datas);
    },
  },
  computed: {
    dashboards: function () {
      return this.$store.getters.getDELIVERYTableData;
    },
    filters: function () {
      // console.log("filters are ", this.$store.getters.getDELIVERYFilter);
      return this.$store.getters.getDELIVERYFilter;
    },
    isDataLoaded: function () {
      return (
        this.$store.getters.getDELIVERYTableData.length > 0 &&
        this.$store.getters.getDELIVERYFilter.length > 0
      );
    },
  },
  beforeMount() {
    this.updateDashboardData();
  },
};
</script>
